import "./WhyEbdaa.css"
import SettingsIcon from "../../assets/settings.svg"
import Buildings from "../../assets/buildings.svg"
import People from "../../assets/top-developers.jpg"
import Logo from "../../assets/logo.svg"
import Tape from "./tape/Tape"


function WhyEbdaa() {
  return (
    <div className="why-ebdaa" id="why-ebdaa">
      <div className="title" data-aos="fade-right" data-aos-delay="200">
        Why EBDAA
      </div>
      <div className="why-ebdaa-container">
        <div className="left">
          <div className="tapes-container">
            <Tape
              icon={SettingsIcon}
              title="Development"
              description="We have one of the best software engineering teams in the country, with high levels of experience in both engineering and training."
              number={0}
            />
            <Tape
              icon={Buildings}
              title="Business Analysis"
              description="Our Business team is expert in data analysis, business counseling, marketing, financial analysis and more!"
              number={1}
            />
          </div>
        </div>
        <div className="right" data-aos="fade-left" data-aos-delay="200">
          <img src={People} alt="people at office" style={{ opacity: '0.5'}} />
          <img src={Logo} alt="Logo" />
        </div>
      </div>
    </div>
  )
}

export default WhyEbdaa
