import "./Form.css"
import FileIcon from "../../assets/file.svg"

function Form({
  backgroundColor,
  titleColor,
  formTitle,
  projectTitle,
  button,
  right,
}) {
  return (
    <form
      data-aos={right ? "fade-left" : "fade-right"}
      data-aos-delay="200"
      onSubmit={(e) => {
        e.preventDefault()
      }}
      className="form-container"
      style={{ backgroundColor: backgroundColor }}
    >
      <div className="form-title" style={{ color: titleColor }}>
        {formTitle}
      </div>
      <div className="input-container">
        <label htmlFor="name">name</label>
        <input type="text" name="name" id="name" />
      </div>
      <div className="input-container">
        <label htmlFor="email">email</label>
        <input type="email" name="email" id="email" />
      </div>
      <div className="input-container">
        <label htmlFor="phone-number">phone number</label>
        <input type="number" name="phone-number" id="phone-number" />
      </div>
      {projectTitle && (
        <div className="input-container">
          <label htmlFor="project-title">project title</label>
          <input type="number" name="project-title" id="project-title" />
        </div>
      )}
      <div className="input-container">
        <label htmlFor="project-details">project details</label>
        <textarea
          name="project-details"
          id="project-details"
          cols="30"
          rows="10"
        ></textarea>
      </div>
      <div className="input-container">
        <label htmlFor="attachment" className="file-input-label">
          attachment
        </label>
        <input
          name="attachment"
          id="attachment"
          className="attachment-input"
          type="file"
        />
        <img src={FileIcon} alt="file icon" className="file-icon" />
      </div>
      <span className="apply-btn">{button}</span>
    </form>
  )
}

export default Form
