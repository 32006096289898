import Logo from "../../assets/logo.svg"
import "./Footer.css"
import R from "../../assets/r.svg"
import Facebook from "../../assets/facebook.svg"
import Twitter from "../../assets/twitter.svg"
import Linkedin from "../../assets/linkedin.svg"

function Footer() {
  return (
    <footer id="footer">
      <div className="footer-logo" data-aos="fade-right" data-aos-delay="200">
        <img src={Logo} alt="Logo" />
      </div>
      <div className="footer-content">
        <div className="address footer-item" data-aos="fade-up" data-aos-delay="200">
          <h5>Address</h5>
          <div className="address-text">
            unit 27, 10th floor, town mall, elkhan street, Tanta, Egypt
          </div>
          <div className="address-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d914.5469462174988!2d31.000898607870205!3d30.786146253989806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14f7c91771807ecb%3A0xd701dd6a2b9ecff!2sTanta%20Town%20Mall!5e1!3m2!1sar!2seg!4v1681746547046!5m2!1sar!2seg"
              title="Tanta Town Mall Location"
              width="600"
              height="450"
              
              style={{ border: '1px solid black' }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div className="about footer-item" data-aos="fade-up" data-aos-delay="250">
          <h5>About</h5>
          <span>terms and services</span>
          <span>MegaDev</span>
          <span>our projects</span>
        </div>
        <div className="support footer-item" data-aos="fade-up" data-aos-delay="300">
          <h5>Support</h5>
          <span>call us: 01023551019</span>
          <span>email: EBDAA.development@gmail.com</span>
        </div>
        <div className="copy-right-and-social-links">
          <div className="copy-right">
            All Rights Reserved EBDAA 2022{" "}
            <sup>
              <img src={R} alt="r" />
            </sup>
          </div>
          <div className="social-links">
            <a href="#main">
              <img src={Facebook} alt="facebook" />
            </a>
            <a href="#main">
              <img src={Twitter} alt="twitter" />
            </a>
            <a href="#main">
              <img src={Linkedin} alt="linkedin" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
