import Aos from "aos"
import "aos/dist/aos.css"
import ContactUs from "./components/contectUs/ContactUs"
import Footer from "./components/footer/Footer"
import HowCanWeHelp from "./components/howCanWeHelp/HowCanWeHelp"
import Main from "./components/main/Main"
import NavBar from "./components/navbar/NavBar"
import OurTeam from "./components/ourTeam/OurTeam"
import OurTrainingPrograms from "./components/ourTrainingPrograms/OurTrainingPrograms"
import WhoAreWe from "./components/whoAreWe/WhoAreWe"
import WhyEbdaa from "./components/whyEbdaa/WhyEbdaa"
import { useEffect } from "react"

function App() {
  useEffect(() => {
    Aos.init({
      duration: 800,
    })
  }, [])

  return (
    <div className="app">
      <div className="container">
        <NavBar />
      </div>
      <Main />
      <div className="container">
        <WhoAreWe />
        <HowCanWeHelp />
      </div>
      <OurTeam />
      <div className="container">
        <WhyEbdaa />
        <OurTrainingPrograms />
        <ContactUs />
      </div>
      <Footer />
    </div>
  )
}

export default App
