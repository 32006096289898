import "./Tape.css"
function Tape({ icon, title, description, number }) {
  return (
    <div
      className="tape-container"
      data-aos="fade-right"
      data-aos-delay={`${200 + number * 150}`}
    >
      <div className="tape-left">
        <img src={icon} alt="Settings icon" />
      </div>
      <div className="tape-right">
        <div className="tape-right__title">{title}:</div>
        <div className="tape-right__description">{description}</div>
      </div>
    </div>
  )
}

export default Tape
