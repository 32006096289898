import { useState } from "react"
import Logo from "../../assets/logo.svg"
import MenuIcon from "../../assets/menu-icon.svg"

import "./NavBar.css"

function NavBar() {
  const [activeLinks, setActiveLinks] = useState(false)

  return (
    <header>
      <nav>
        <div className="nav__logo" data-aos="fade-right" data-aos-delay="150">
          <img src={Logo} alt="Ebdaa Logo" />
        </div>
        <div
          className="nav__menu-icon"
          onClick={() => setActiveLinks(!activeLinks)}
        >
          <img src={MenuIcon} alt="Menu Icon" />
        </div>
        <ul className={activeLinks ? "nav__links exposed" : "nav__links"}>
          <li>
            <a href="#who-are-we">who are we</a>
          </li>
          <li>
            <a href="#how-can-we-help-section">how can we help</a>
          </li>
          <li>
            <a href="#our-team">our team</a>
          </li>
          <li>
            <a href="#why-ebdaa">why EBDAA</a>
          </li>
          <li>
            <a href="#our-training-program">training programs</a>
          </li>
          <li>
            <a href="#contact-us">contact us</a>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default NavBar
