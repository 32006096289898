import "./Part.css"
import OurTeamCard from "./ourTeamCard/OurTeamCard"

function Part({ cardOne, cardTwo, cardThree, direction }) {
  const dir =
    direction === "right"
      ? { justifyContent: "end" }
      : { justifyContent: "start" }

  return (
    <div className="part-container" style={dir}>
      <div className="cards-container">
        <OurTeamCard card={cardOne} number={0} />
        <OurTeamCard card={cardTwo} number={1} />
        <OurTeamCard card={cardThree} number={2} />
      </div>
    </div>
  )
}

export default Part
