import Circle from "./circle/Circle"
import PcIcon from "../../assets/pc.png"
import PersonIcon from "../../assets/person.svg"
import HandShake from "../../assets/handShake.svg"
import "./HowCanWeHelp.css"

function HowCanWeHelp() {
  return (
    <section id="how-can-we-help-section" className="how-can-we-help-section">
      <div
        className="how-can-we-help-section__title"
        data-aos="fade-right"
        data-aos-delay="200"
      >
        How can we help?
      </div>
      <div className="circles">
        <Circle
          icon={PcIcon}
          title="Software Solutions"
          text="websites, applications, designs, and more."
          number={0}
        />
        <Circle
          icon={PersonIcon}
          title="Trainings"
          text="Trainings in different software tracks, presented by our professional engineering team."
          number={1}
        />
        <Circle
          icon={HandShake}
          title="Technical Consults "
          text="We will guide you with your software projects; where to start, how to do, and answering all your questions."
          number={2}
        />
      </div>
    </section>
  )
}

export default HowCanWeHelp
