import "./Program.css"

function Program({ color, image, title, description, number }) {
  return (
    <div
      className="program-container"
      style={{ color }}
      data-aos={number % 2 === 0 ? "fade-right" : "fade-left"}
      data-aos-delay={`${200 + number * 150}`}
    >
      <div className="program-icon">
        <img src={image} alt="program icon" />
      </div>
      <div className="program-title" style={{ color }}>
        {title}
      </div>
      <div className="program-description">{description}</div>
      <button
        className="program-enroll-now-button"
        style={{ backgroundColor: color }}
      >
        Enroll now
      </button>
    </div>
  )
}

export default Program
