import Text from "../../assets/header-text-.svg"
import Logo from "../../assets/logo.svg"

import "./Main.css"
function Main() {
  return (
    <main id="main">
      <div className="text-and-logo">
        <div className="text" data-aos="fade-right" data-aos-delay="200">
          <img src={Text} alt="text" />
        </div>
        <div className="main-logo" data-aos="fade-left" data-aos-delay="200">
          <img src={Logo} alt="Logo" />
        </div>
      </div>
    </main>
  )
}

export default Main
