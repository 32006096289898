import "./WhoAreWe.css"
function WhoAreWe() {
  return (
    <section id="who-are-we" className="who-are-we-section">
      <div className="title" data-aos="fade-right" data-aos-delay="200">Who are we?</div>
      <div className="content" data-aos="fade-up" data-aos-delay="200">
        <p>
          we are a software house and a training hub, that consider creating
          software tools and training courses to help students creating their
          own projects.
        </p>
      </div>
    </section>
  )
}

export default WhoAreWe
