import "./OurTeamCard.css"

function OurTeamCard({ card, number }) {
  return (
    <div
      className="card-container"
      data-aos="fade-up"
      data-aos-delay={`${200 + number * 150}`}
    >
      <div className="job-title">{card.jobTitle}</div>
      <div className="our-team-card-container">
        <div className="member-image">
          <img src={card.img} alt="team member img" />
        </div>
        <h4>{card.name}</h4>
        <p>{card.description}</p>
      </div>
    </div>
  )
}

export default OurTeamCard
