import "./OurTeam.css"
import Part from "./part/Part"
import Person1 from "../../assets/person-1.svg"
import Person2 from "../../assets/person-2.svg"
import Person3 from "../../assets/person-3.svg"
import Person4 from "../../assets/person-4.svg"
import Person5 from "../../assets/person-5.svg"
import Person6 from "../../assets/person-6.svg"
import OurTeamCard from "./part/ourTeamCard/OurTeamCard"
import Man from '../../assets/man.png'
import Woman from '../../assets/woman.svg'

function OurTeam() {
  const cardOne = {
    jobTitle: "Backend Team",
    img: Man,
    name: "Ahmed Khaled",
    description: `Back end team lead.
    4 years experience in backend development with java, 2 years backend development with python.`,
  }
  const cardTwo = {
    jobTitle: "Frontend Team",
    img: Man,
    name: "Kareem Shazly",
    description: `Mobile development team lead.
    2 years experience in android development with java, 2 years flutter mobile development.`,
  }
  const cardThree = {
    jobTitle: "Mobile Dev Team",
    img: Woman,
    name: "Salma Mohamed",
    description: `front end team lead.
    3 years experience in frontend development(angular, react, JavaScript).`,
  }
  const cardFour = {
    jobTitle: "UI/Ux Team",
    img: Woman,
    name: "Amal Walid",
    description: `Ui/Ux team lead.
    2 years experience in UI/UX design (Figma), 3 years as a graphic designer.`,
  }
  const cardFive = {
    jobTitle: "Business Team",
    img: Man,
    name: "Mohamed Salem",
    description: `Business team lead.
    4 years experience in business administration.`,
  }
  const cardSix = {
    jobTitle: "Marketing Team",
    img: Woman,
    name: "Eman Marwan",
    description: `Marketing team lead.
    3 years experience as marketing manager, 3 years as e-marketing expert.`,
  }

  // const cards = [cardOne, cardTwo, cardThree, cardFour, cardFive, cardSix]

  return (
    <section id="our-team" className="our-team">
      <div className="our-team__title" data-aos="fade-right" data-aos-delay="200">Our teams</div>
      <div className="parts-container">
        <Part
          cardOne={cardOne}
          cardTwo={cardTwo}
          cardThree={cardThree}
          direction="right"
        />
        <Part
          cardOne={cardFour}
          cardTwo={cardFive}
          cardThree={cardSix}
          direction="left"
        />
      </div>
      <div className="parts-container mobile">
        <div className="part-container">
          <div className="cards-container">
            <OurTeamCard card={cardOne} />
            <OurTeamCard card={cardTwo} />
            <OurTeamCard card={cardThree} />
            <OurTeamCard card={cardFour} />
            <OurTeamCard card={cardFive} />
            <OurTeamCard card={cardSix} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurTeam
