import StudentFormImage from "../../assets/student-form.svg"
import BusinessFormImage from "../../assets/business-form.svg"
import "./ContactUs.css"
import Form from "../form/Form"
import Button from "../button/Button"
import Button2 from "../button2/Button2"

function ContactUs() {
  return (
    <div className="contact-us" id="contact-us">
      <div className="title" data-aos="fade-right" data-aos-delay="200">
        Contact us!
      </div>
      <div className="student-form">
        <div
          className="student-form-image"
          data-aos="fade-right"
          data-aos-delay="200"
        >
          <img src={StudentFormImage} alt="student form" />
        </div>
        <Form
          backgroundColor="#DBF3DB"
          titleColor="#0F620F"
          formTitle="Student Form"
          projectTitle={false}
          button={<Button />}
          right={true}
        />
      </div>
      <div className="business-owner-form">
        <Form
          backgroundColor="#DBE6F3"
          titleColor="#0F3B62"
          formTitle="Business owner Form"
          projectTitle={true}
          button={<Button2 />}
          right={false}
        />
        <div
          className="business-owner-form-image"
          data-aos="fade-left"
          data-aos-delay="200"
        >
          <img src={BusinessFormImage} alt="student form" />
        </div>
      </div>
    </div>
  )
}

export default ContactUs
