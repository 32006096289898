import "./Circle.css"

function Circle({ icon, title, text, number }) {
  return (
    <div
      className="circle-container"
      data-aos="fade-up"
      data-aos-delay={`${200 + number * 150}`}
    >
      <div className="icon">
        <img src={icon} alt="Icon" />
      </div>
      <div className="title">{title}</div>
      <div className="text">{text}</div>
    </div>
  )
}

export default Circle
