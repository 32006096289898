import "./OurTrainingPrograms.css"
import Program from "./program/Program"
import Laptop from "../../assets/laptop.svg"
import SettingsIcon from "../../assets/settingsIcon.png"
import AndriodPhone from "../../assets/mobile-android.svg"
import IosPhone from "../../assets/mobile-iphone.svg"
import Flutter from "../../assets/mobile-flutter.png"
import UIUX from "../../assets/ui-ux.png"

function OurTrainingPrograms() {
  return (
    <div className="our-training-program" id="our-training-program">
      <div className="title" data-aos="fade-right" data-aos-delay="200">
        Our training programs
      </div>
      <div className="programs-container">
        <Program
          color="#69D1EC"
          image={Laptop}
          title="Frontend Program"
          description="3 months program with a final exam and a certificate.
          certified trainer with an updated material.
          (html, CSS, JavaScript, react)"
          number={0}
        />
        <Program
          color="#950000"
          image={SettingsIcon}
          title="Backend Program"
          description="3 months program with a final exam and a certificate.
          certified trainer with an updated material.
          (python/java, SQL)"
          number={1}
        />
        <Program
          color="#0A9A52"
          image={AndriodPhone}
          title="Android Program"
          description="3 months program with a final exam and a certificate.
          certified trainer with an updated material.
          (xml, java/kotlin)"
          number={2}
        />
        <Program
          color="#810AA4"
          image={IosPhone}
          title="iOS Program"
          description="3 months program with a final exam and a certificate.
          certified trainer with an updated material.
          (swift, Xcode)"
          number={3}
        />
        <Program
          color="#EEE131"
          image={Flutter}
          title="Flutter Program"
          description="3 months program with a final exam and a certificate.
          certified trainer with an updated material.
          (Dart)"
          number={4}
        />
        <Program
          color="#EC159D"
          image={UIUX}
          title="UI/UX Program"
          description="3 months program with a final exam and a certificate.
          certified trainer with an updated material.
          (Figma, material design)"
          number={5}
        />
      </div>
    </div>
  )
}

export default OurTrainingPrograms
